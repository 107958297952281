import { useState } from "react";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import useAuth from "utils/useAuth";

// Authentication layout components
import IllustrationLayout from "pages/sign-in/components/IllustrationLayout";

// Image
import bgImage from "../../assets/images/reply/reply-test-automation-white.png";

function SignIn({navbarRefresher}) {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const auth = useAuth();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  function onKeyDown(event) {
    if (event.key === 'Enter') {
      handleLogin();
    }
  }

  function handleLogin() {
    auth.signIn(email, password, rememberMe, navbarRefresher);
  }

  const isLoggedIn = useAuth().ensureLoggedIn();
  if (isLoggedIn) {
    return <Navigate replace to="/test-execution" />;
  }

  let color = "info";
  if (auth.isLoading) {
    color = "info";
  } else if (auth.error != null) {
    color = "error";
  }

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={{
        image: bgImage,
        title: 'TEST AUTOMATION FRAMEWORK',
        description: "Concept Quality Reply",
        version: "v 3.1.1",
      }}
    >
      <ArgonBox component="form" role="form">
        <ArgonBox mb={2}>
          <ArgonInput
            type="email"
            placeholder="Email"
            size="large"
            onClick={auth.reset}
            onChange={(e) => setEmail(e.target.value)}
          />
        </ArgonBox>
        <ArgonBox mb={2}>
          <ArgonInput
            type="password"
            placeholder="Password"
            size="large"
            onClick={auth.reset}
            onKeyDown={onKeyDown}
            onChange={(e) => setPassword(e.target.value)}
          />
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <ArgonTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox mt={4} mb={1}>
          <ArgonButton
            color={color}
            variant="gradient"
            size="large"
            fullWidth
            onClick={auth.isLoading ? null : handleLogin}
          >
            {auth.isLoading ? "Logging in..." : "Sign In"}
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
    </IllustrationLayout>
  );
}

export default SignIn;
