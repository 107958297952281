// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI contexts
import Gitlab from "./Account/Gitlab";
import Jira from "./Account/Jira";
import AzureDevops from "./Account/AzureDevops";
import Figma from "./Account/Figma";
import GitHub from "./Account/GitHub";

function Accounts({user,account,setAccount}) {

    return (
        <Card id="accounts">
            <ArgonBox p={3} lineHeight={1}>
                <ArgonBox mb={1}>
                    <ArgonTypography variant="h5">Accounts</ArgonTypography>
                </ArgonBox>
                <ArgonTypography variant="button" color="text" fontWeight="regular">
                    Here you can setup and manage your integration settings.
                </ArgonTypography>
            </ArgonBox>
            <ArgonBox pt={2} pb={3} px={3}>
                <Gitlab user={user} account={account} setAccount={setAccount}/>
                <GitHub user={user} account={account} setAccount={setAccount}/>
                <Jira user={user} account={account} setAccount={setAccount}/>
                <AzureDevops user={user} account={account} setAccount={setAccount}/>
                <Figma />
                <Divider />
            </ArgonBox>
        </Card>
    );
}

export default Accounts;
