import { Navigate, useLocation, useNavigate } from "react-router-dom";

import React, { useEffect, useState, forwardRef } from "react";

import Grid from "@mui/material/Grid";
import useAuth from "utils/useAuth";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from "@mui/material/Tooltip";

import ArgonBox from "components/ArgonBox";
import moment from "moment";
import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";

import { TestRunsAPI } from "api/BackendApi/TestRuns";
import { HUBAPI } from "api/BackendApi/HUB";
import { UsersApi } from "api/BackendApi/Users";

import SummarizeIcon from '@mui/icons-material/Summarize';
import { enqueueSnackbar } from "notistack";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import Card from "@mui/material/Card";
import Header from "components/Header";
import DataTable from "components/DataTable";
import ArgonBadge from "components/ArgonBadge";
import { CircularProgress, Fade, IconButton, Menu, MenuItem, tooltipClasses } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import ReportView from "./components/ReportView";
import { getTest } from "api/BackendApi/TestRepository";
import AvatarWithName from "components/AvatarWithName";
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from "@mui/material/styles";
import logoJira from "../../assets/images/small-logos/logo-jira.svg";
import {JiraTool} from "../generative-test/components/JiraTool";
import {getAllIntegration} from "../../api/BackendApi/Account";


const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
  width: pxToRem(34),
  minWidth: pxToRem(34),
  height: pxToRem(34),
  minHeight: pxToRem(34),
  mr: 1
});

const loading = () => {
  return (<ArgonBox
    style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <CircularProgress size={64} />
  </ArgonBox>);
};

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

const statusColorMap = {
  "PASSED": "success",
  "FAILED": "error",
  "N/A": "error"
};


function BasicMenu({ children }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (callable) => {
    handleClose();
    callable();
  };
  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuOpenIcon color="white" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children.map(m => <MenuItem key={m.title} onClick={() => handleSelection(m.callback)}>{m.title}</MenuItem>)}
      </Menu>
    </div>
  );
}


function TestResults() {
  const isLoggedIn = useAuth().ensureLoggedIn();
  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedInstances, setSelectedInstances] = useState(location.state.instances);

  const [isLoadingRuns, setIsLoadingRuns] = useState(true);

  const [tests, setTests] = useState(null);
  const [runs, setRuns] = useState(null);
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);
  const [userIntegrations, setUserIntegrations] = useState(null);

  const [openedReportRun, setOpenedReportRun] = useState(null);
  const [openedJiraModal, setOpenedJiraModal] = useState(false);

  const [connectedJira, setConnectedJira] = useState(false);
  const [dataJira, setDataJira] = useState([]);
  const [projectsJira, setProjectsJira] = useState([]);

  const [testNameSelected, setTestNameSelected] = useState(null);
  const [reportSelected, setReportSelected] = useState(null);
  const [testInstanceSelected, setTestInstanceSelected] = useState(null);

  const openReportInNewTab = async (run_id) => {
    let response = await TestRunsAPI.getRunReportLink(run_id);
    // TODO URL should match node's
    openInNewTab("/be/test-runs" + response.data);
  };

  const downloadFile = (name, text, content_type) => {
    const element = document.createElement("a");
    const file = new Blob([text]);
    element.id = name
    element.href = URL.createObjectURL(file);
    element.download = name;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  }

  const downloadLogsFile = async (testId, testName, testTimestamp) => {
    var response = await HUBAPI.getRunLogs(testId);
    var logs = response.data.logs;
    downloadFile("logs_" + testName.replaceAll(" ", "_").toLowerCase() + "_" + testTimestamp + ".txt", logs.join(""), 'text/plain');
  };

  const downloadReportFile = async (testId, fileName) => {
    let response = await TestRunsAPI.getRunReport(testId);
    downloadFile(fileName, response.data, "application/octet-stream");
  }

  const deleteRun = async (runId) => {
    try {
      console.log("Delete run id: " + runId);
      await TestRunsAPI.deleteRun(runId);
      enqueueSnackbar("Run deleted!", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Could not delete run", { variant: "error" })
    } finally {
      loadRuns();
    }
  }

  const handleReportViewClose = () => {
    setOpenedReportRun(null);
  }

  const handleJiraViewClose = () => {
    setOpenedJiraModal(false);
  }

  const loadRuns = async () => {
    let singleRun = selectedInstances.length > 1;
    let allRuns = [];
    let allTests = [];
    for (let instance of selectedInstances) {
      console.log(instance)
      let runsResponse = await TestRunsAPI.getTestRunsForInstance(instance._id);
      if (singleRun) {
        if (runsResponse.data.runs.length > 0) {
          allRuns.push(runsResponse.data.runs[0]); // Runs should be ordered from latest to oldest
        }
      } else {
        allRuns = allRuns.concat(runsResponse.data.runs);
      }

      var testResponse = await getTest(instance.test._id);
      allTests.push(testResponse.data.test);
    }

    setRuns(allRuns);
    setTests(allTests);
  }

  const downloadAllReports = async () => {
    try {
      var ids = runs.map(i => i._id);
      var response = await TestRunsAPI.getReportsFiles(ids);
      downloadFile("reports.zip", response.data, "application/octet-stream");
      enqueueSnackbar("Downloading reports!", { variant: "success" })
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to download all reports", { variant: "error" })
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoadingRuns(true);
        let usersResponse = await UsersApi.getUsers();
        setUsers(usersResponse.data.users);
        let getUserResponse = await UsersApi.getMe();
        setUser(getUserResponse.data.user);
        let getIntegration = await getAllIntegration(getUserResponse.data.user);
        setUserIntegrations(getIntegration.data.account);

        loadRuns();
      } catch (e) {
        throw e;
      } finally {
        setIsLoadingRuns(false);
      }
    }
    fetchData();
  }, []);

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  let columns = [];

  if(selectedInstances.length > 1){
    columns = [
      { Header: "Name", accessor: "name", maxWidth: "30vw" },
      { Header: "Exec Date", accessor: "execDate", align: "center" },
      { Header: "Tester", accessor: "tester", align: "center" },
      { Header: "Capability", accessor: "capability", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Actions", accessor: "report", width: "10%", align: "center" },
    ];
  }else{
    columns = [
      { Header: "Name", accessor: "name", maxWidth: "30vw" },
      { Header: "Exec Date", accessor: "execDate", align: "center" },
      { Header: "Tester", accessor: "tester", align: "center" },
      { Header: "Capability", accessor: "capability", align: "center" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Actions", accessor: "report", width: "10%", align: "center" },
      { Header: "Integrations", accessor: "integrations", width: "10%", align: "center" },
    ];
  }

  let body = loading();

  if (!isLoadingRuns && runs != null && tests != null) {
    body = <Card>
      <ArgonBox pl={2} pr={2}>
        <DataTable
          pagination
          entriesPerPage={{ defaultValue: 25, entries: [10, 25, 50, 100] }}
          showTotalEntries={true}
          enableSelection={false}
          actions={
            selectedInstances.length > 1 && <ArgonButton iconOnly variant="contained" color="primary" size="large" sx={buttonStyleLeft} onClick={downloadAllReports}>
              <NoMaxWidthTooltip title="Download all reports" placement="top">
                <DownloadIcon />
              </NoMaxWidthTooltip>
            </ArgonButton>
          }
          table={{
            columns: columns,
            rows: runs.map((run) => {
              const format1 = "YYYY-MM-DD HH:mm:ss"
              let date = new Date(run.finishingTime);
              let finishingTime = moment(date).utcOffset(0).format(format1);

              let color = statusColorMap[run.runResult.toUpperCase()];
              let badgeContent = run.runResult;

              let isAccessibility = run.additionalOutput?.accessibility != null;
              if (isAccessibility) {
                // run.additionalOutput.accessibility.impact
                color = "error";
                badgeContent = run.additionalOutput.accessibility.failPercentage.toFixed(2) + "%";
              }

              let testInstanceId = run.testInstance.id
              let testId = selectedInstances.find(i => i._id === run.testInstance.id).test._id;
              let test = tests.find(t => t._id === testId);

              if(selectedInstances.length > 1) {
                return {
                  name: test.name,
                  execDate: finishingTime,
                  tester: <AvatarWithName user={users.find(u => u._id == run.user.id)} />,
                  capability: run.capabilityId.split('-')[0],
                  status: <ArgonBadge container color={color} variant="contained" size="md" badgeContent={badgeContent} />,
                  report: <div>
                    <ArgonButton variant="contained" color="primary" size="large" iconOnly sx={buttonStyleLeft}>
                      <SummarizeIcon onClick={() => { setOpenedReportRun(run); }} />
                    </ArgonButton>
                    <ArgonButton variant="contained" color="primary" size="large" iconOnly sx={buttonStyleLeft}>
                      <BasicMenu>
                        {
                          [
                            { title: "Open in new tab", callback: () => openReportInNewTab(run._id) },
                            { title: "Download Logs", callback: () => downloadLogsFile(run.orch_test_id, test.name, run.finishingTime) },
                            { title: "Download Report", callback: () => downloadReportFile(run._id, run.fileName) },
                            { title: "Delete", callback: () => deleteRun(run._id) }
                          ]
                        }
                      </BasicMenu>
                    </ArgonButton>
                  </div>
                };
              }else{
                return {
                  name: test.name,
                  execDate: finishingTime,
                  tester: <AvatarWithName user={users.find(u => u._id === run.user.id)} />,
                  capability: run.capabilityId.split('-')[0],
                  status: <ArgonBadge container color={color} variant="contained" size="md" badgeContent={badgeContent} />,
                  report: <div>
                    <ArgonButton variant="contained" color="primary" size="large" iconOnly sx={buttonStyleLeft}>
                      <SummarizeIcon onClick={() => { setOpenedReportRun(run); }} />
                    </ArgonButton>
                    <ArgonButton variant="contained" color="primary" size="large" iconOnly sx={buttonStyleLeft}>
                      <BasicMenu>
                        {
                          [
                            { title: "Open in new tab", callback: () => openReportInNewTab(run._id) },
                            { title: "Download Logs", callback: () => downloadLogsFile(run.orch_test_id, test.name, run.finishingTime) },
                            { title: "Download Report", callback: () => downloadReportFile(run._id, run.fileName) },
                            { title: "Delete", callback: () => deleteRun(run._id) }
                          ]
                        }
                      </BasicMenu>
                    </ArgonButton>
                  </div>,
                  integrations: <div>
                    <ArgonButton variant="contained" color="light" size="large" iconOnly sx={buttonStyleLeft} onClick={async () => {
                      setTestNameSelected(test.name);
                      setTestInstanceSelected(testInstanceId);
                      try {
                        let response = await TestRunsAPI.getRunReport(run._id);
                        let file = new File([ new Blob([response.data], { type: "application/octet-stream" })], "Report_" + test.name + "_" + moment().format("YYYY-MM-DD_HH-mm-ss") + ".zip", { type: "application/zip" });
                        setReportSelected(file);
                      }catch (e) {
                        console.log("Error downloading report: " + e);
                        setReportSelected(null);
                      }
                      setOpenedJiraModal(true);
                    }}>
                      <img src={logoJira} style={{width: "2vw", height: "2vw"}} alt="logoJira"/>
                    </ArgonButton>
                  </div>,
                };
              }
            })
          }}
        />
      </ArgonBox>
    </Card>;
  }

  const Transition = forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
  });

  return (
    <DashboardLayout>
      <Header />
      {openedReportRun != null && <Dialog
        open={true}
        onClose={handleReportViewClose}
        fullWidth
        maxWidth={false}
        TransitionComponent={Transition}
        transitionDuration={700}
        keepMounted
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ marginLeft: "3%", marginRight: "3%" }}
      >
        <DialogContent key="content" style={{ height: "85vh" }}>
          <ReportView closeReport={handleReportViewClose} runId={openedReportRun?._id} />
        </DialogContent>
      </Dialog>}
      {openedJiraModal && <Dialog
          open={true}
          onClose={handleJiraViewClose}
          fullWidth
          maxWidth={"lg"}
          TransitionComponent={Transition}
          transitionDuration={700}
          keepMounted
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <JiraTool userIntegrations={userIntegrations}
                    setManagementSend={setOpenedJiraModal}
                    jira={{connectedJira, setConnectedJira, dataJira, setDataJira, projectsJira, setProjectsJira}}
                    expandedDialog={true}
                    module={"TestResult"}
                    otherInfo={{testNameSelected,reportSelected,testInstanceSelected}}/>
      </Dialog>}

      <ArgonBox mt={5} mb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            {body}
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout >
  );
}

export default TestResults;