import { useState, useEffect } from "react";
// react-router components
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";
import TestSchedule from "pages/test-schedule/TestSchedule";
import TestResults from "pages/test-results/TestResults";

import BarChartIcon from '@mui/icons-material/BarChart';
import DashboardPage from "pages/dashboard/DashboardPage";

// Argon Dashboard 2 PRO MUI themes
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

// Images
import brand from "assets/images/GTAC.png";
import brandDark from "assets/images/GTAC.png";

// Icon Fonts
import "assets/css/nucleo-icons.css";
import "assets/css/nucleo-svg.css";
import "assets/css/override.css";

import SignIn from "pages/sign-in/SignIn";
import LogoutIcon from '@mui/icons-material/Logout';

// Argon Dashboard 2 PRO MUI routes
import routes from "routes";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController, setMiniSidenav, setOpenConfigurator } from "context";

import { Module } from "api/BackendApi/Module";
import useAuth from "utils/useAuth";
import SplashScreen from "pages/splashscreen/SplashScreen";
import { UsersApi } from "api/BackendApi/Users";
import { Roles } from "api/BackendApi/Roles";

export default function App() {
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, darkSidenav, darkMode } =
    controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  const navbarRefresher = (caller) => {
    setUpdatedRoutesCount(updateRoutesCount + 1);
  }

  const [updatedRoutes, setUpdatedRoutes] = useState([{
    type: "link",
    name: "Sign Out",
    key: "sign-out",
    icon: <LogoutIcon />,
    route: "sign-in",
    noCollapse: true,
    onClick: () => {
      // TODO make a cool logout function
      localStorage.removeItem("jwt-token");
      sessionStorage.removeItem("jwt-token");
    },
    component: <SignIn navbarRefresher={navbarRefresher} />,
  }]);

  const [updateRoutesCount, setUpdatedRoutesCount] = useState(0);

  const isLoggedIn = useAuth().ensureLoggedIn();

  const navigate = useNavigate();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getDashboards = async () => {
    let response = await Module.getModuleByName("DashboardContainer");
    var boards = response.data.modules.dashboard.map(d => d[0]);
    return {
      boards: boards,
      enabled: boards.every(b => b.enabled)
    };
  };

  const firstAvailableRoute = () => {
    var firstRoute = updatedRoutes[0];
    if (firstRoute.collapse != null) {
      return firstRoute.collapse[0].route;
    }
    return firstRoute.route;
  };

  const getBoardsRoute = (boardModule) => {
    if (!window.user.canViewDashboards()) return null;

    let multi = boardModule.boards.length > 1;

    let collapses = boardModule.boards.map(d => {
      let name = d.name.replaceAll(" ", "-");
      return {
        type: "link",
        name: d.name,
        key: name,
        route: "dashboards/" + name,
        icon: <BarChartIcon />,
        noCollapse: true,
        component: <DashboardPage dashboardId={d._id} dashbaordName={name} />
      }
    });
    if (multi) {
      return {
        type: "collapse",
        name: "Dashboards",
        key: "dashboards",
        noCollapse: false,
        icon: <BarChartIcon />,
        collapse: collapses
      }
    } else {
      collapses[0].key = "dashboards"
      return collapses[0]
    }
  };

  const getModules = async () => {
    let response = await Module.getModulesAll();
    return response.data.modules;
  };

  const isModuleEnabled = (modules, moduleName) => {
    var module = modules.find(m => m.name == moduleName);
    var hasPermission = true;

    var routePermissions = {
      "Chart Configuration": window.user.canViewChartConfiguration,
      "Locator Monitoring":  window.user.canViewMonitoringLocator,
      "Execution Progress": window.user.canViewExecutionProgress,
      "Test Repository": window.user.canViewTestRepo,
      "Test Execution": window.user.canViewTestExecution,
      "Sitemap Website": window.user.canViewSitemapWebsite,
      "Generative Test": window.user.canViewGenerativeTest,
      "User Management": window.user.canViewUserMgmt,
      "Role Management": window.user.canViewRoleMgmt,
      "Module Management": window.user.canViewModuleMgmt,
      "Filter Configuration": window.user.canViewFilterConfig,
      "Taffy": window.user.canViewTaffy
    }
    
    var allowGetter = () => true;
    var permGetter = routePermissions[moduleName] ?? allowGetter;

    return module != null && module.enabled && permGetter();
  };

  useEffect(() => {
    if (location.pathname.endsWith("sign-in")) {
      navigate(firstAvailableRoute());
    }
  }, [updatedRoutes]);

  useEffect(() => {
    if (!isLoggedIn) {
      if (!location.pathname.endsWith("sign-in")) {
        navigate('/sign-in');
      }
      return;
    }

    let getPermissionsDict = () => {
      return {
        canDeleteDashboard: () => window.permissions.can('Dashboard Delete'),
        canEditChart: () => window.permissions.can('Chart Edit'),
        canViewChartConfiguration: () => window.permissions.can('Chart Configuration View'),
        canCreateConfigType: () => window.permissions.can('Type Creation'),
        canToggleType: () => window.permissions.can('Type Enable/Disable'),
        canToggleModuleForType: () => window.permissions.can('Module for Type Enable/Disable'),
        canSelfHealing: () => window.permissions.can('Self Healing'),
        canViewMonitoringLocator: () => window.permissions.can('Locator Monitoring View'),
        canViewExecutionProgress: () => window.permissions.can('Execution Progress View'),
        canCreateTestRepo: () => window.permissions.can('Test Repository Creation'),
        canEditTestRepo: () => window.permissions.can('Test Repository Edit'),
        canImportTestRepo: () => window.permissions.can('Test Repository Import'),
        canExportTestRepo: () => window.permissions.can('Test Repository Export'),
        canDeleteTestRepo: () => window.permissions.can('Test Repository Delete'),
        canViewTestRepo: () => window.permissions.can('Test Repository View'),
        canCreateTestExec: () => window.permissions.can('Test Execution Creation'),
        canEditTestExec: () => window.permissions.can('Test Execution Edit'),
        canExecuteTestCase: () => window.permissions.can('Test case Execution'),
        canExecuteQuickTestCase: () => window.permissions.can('Test case Quick Execution'), // Not used yet
        canConfigureTestCycle: () => window.permissions.can('Test Cycle Configuration'),
        canViewTestExecution: () => window.permissions.can('Test Execution View'),
        canViewSitemapWebsite: () => window.permissions.can('Sitemap Website View'),
        canViewGenerativeTest: () => window.permissions.can('Generative Test View'),
        canCreateUser: () => window.permissions.can('User Creation'),
        canEditUser: () => window.permissions.can('User Edit'),
        canToggleUser: () => window.permissions.can('User Enable/Disable'),
        canViewUserMgmt: () => window.permissions.can('User Management View'),
        canCreateRole: () => window.permissions.can('Role Creation'),
        canEditRole: () => window.permissions.can('Role Edit'),
        canDeleteRole: () => window.permissions.can('Role Delete'),
        canAssignRightToRole: () => window.permissions.can('Right Assign to Role'),
        canViewRoleMgmt: () => window.permissions.can('Role Management View'),
        canViewModuleMgmt: () => window.permissions.can('Module Management View'),
        canViewTaffy: () => window.permissions.can('Taffy View'),
        canViewDashboards: () => window.permissions.can('Dashboards View'),
        canCreateDashboard: () => window.permissions.can('Dashboard Creation'),
        canCreateConfig: () => window.permissions.can('Config Creation'),
        canEditConfigType: () => window.permissions.can('Config Edit'),
        canViewFilterConfig: () => window.permissions.can('Filter Configuration View'),
        canAddLocator: () => window.permissions.can('Add Locator'),
        canEditLocator: () => window.permissions.can('Edit Locator'),
        canDeleteLocator: () => window.permissions.can('Delete Locator'),
      }
    }

    let doWork = async () => {
     
      window.permissions = {
        can: (perm_name) => window.user_permissions.find(perm => perm.permission == perm_name)?.enabled ?? false,
        refreshPermissions: async () => {
          var userResponse = await UsersApi.getMe();
          var user = userResponse.data.user;
          var roleResponse = await Roles.getRole(user.role);
          var assignedRoles = roleResponse.data;

          window.user_permissions = assignedRoles.role[0].permissions;
          window.user = {
            ... userResponse.data.user,
            ... getPermissionsDict()
          };
        }
      }

      await window.permissions.refreshPermissions();

      let modules = await getModules();
      let boardModule = await getDashboards();

      var allRoutes = routes(navbarRefresher);
      if (boardModule.enabled) {
        var dashRoute = getBoardsRoute(boardModule);
        var baseRoutes = [];
        if (dashRoute != null) baseRoutes.push(dashRoute);
        allRoutes = [... baseRoutes, ...allRoutes]
      }

      var enabledRoutes = allRoutes.filter(r => r.module == null || isModuleEnabled(modules, r.module));
      window.taffy = false;
      for (var route of enabledRoutes) {
        if (route.key == "chatbot-taffy") {
          window.taffy = true;
        }
      }
      setUpdatedRoutes(enabledRoutes);
    };
    doWork();
  }, [updateRoutesCount]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <ArgonBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </ArgonBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        isLoggedIn && <>
          <Sidenav
            color={sidenavColor}
            brand={darkSidenav || darkMode ? brand : brandDark}
            brandName="TAF"
            routes={updatedRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          {/*<Configurator />*/}
          {/*configsButton*/}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(updatedRoutes)}
        {updatedRoutes.length > 1 && <Route path="*" element={<Navigate to={firstAvailableRoute()} />} />}
        {updatedRoutes.length == 1 && <Route path="*" element={<SplashScreen navbarRefresher={navbarRefresher} />} />}

        <Route path="/test-results" element={<TestResults />} key="test-results" />
        <Route path="/test-schedule" element={<TestSchedule />} key="test-schedule" />
      </Routes>
    </ThemeProvider>
  );
}
